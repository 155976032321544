import { graphql, useStaticQuery } from "gatsby"

const usePrismicCardQuery = () => {
  const data = useStaticQuery(graphql`
    query PriceCardQuery {
      allPrismicPricePage {
        edges {
          node {
            data {
              price_cards {
                image_of_car {
                  url
                }
                luggage_limit_text {
                  text
                }
                name_of_car {
                  text
                }
                vehicle_passenger_limit
                price_per_hour
                outside_london_surcharge_amount
              }
              additional_info {
                html
              }
            }
          }
        }
      }
    }
  `)

  return data.allPrismicPricePage.edges[0].node.data
}

export default usePrismicCardQuery
