import { Link } from "gatsby"
import React from "react"

const renderPriceCard = (vehicle, i) => {
	const image = {
		url: vehicle.image_of_car.url,
		alt: vehicle.image_of_car.alt,
	}
	const vehicleName = vehicle.name_of_car.text
	const pricePerHour = vehicle.price_per_hour
	const surcharge = vehicle.outside_london_surcharge_amount
	const passengerLimit = vehicle.vehicle_passenger_limit
	const luggageLimitText = vehicle.luggage_limit_text.text

	return (
		<div key={`price-card-${i}`} className="price-list__card">
			<h3>{vehicleName}</h3>
			<img src={image.url} alt={image.alt} />
			<span className="price">£{pricePerHour}/ hour</span>
			<small>£{surcharge} surcharge per hour for services outside London</small>
			<p>Passengers: {passengerLimit}</p>
			<p>Luggage: {luggageLimitText}</p>
			<Link to="/quote">
				<button>Select</button>
			</Link>
		</div>
	)
}

export default renderPriceCard
