import "./price-cards.scss"

import React from "react"
import renderPriceCard from "./renderPriceCard"
import usePrismicCardQuery from "./price-cards.graphql"

const PriceCards = () => {
  const { price_cards, additional_info } = usePrismicCardQuery()

  return (
    <>
      <div className="price-list__container">
        <h2>Our price list</h2>
        {price_cards && price_cards.map(renderPriceCard)}
        <div
          className="additional-info"
          dangerouslySetInnerHTML={{ __html: additional_info.html }}
        ></div>
      </div>
    </>
  )
}

export default PriceCards
