import Layout from "../components/Layout"
import PriceCards from "../components/PriceCards/PriceCards"
import React from "react"
import SEO from "../components/seo"

const RatesPage = ({ data }) => {
	return (
		<Layout>
			<SEO title="Rates" />
			<PriceCards />
		</Layout>
	)
}

export default RatesPage
